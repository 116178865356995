import React, { memo } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";

import useIsRole from "../../hooks/useIsRole";
import useMarkers from "../../hooks/useMarkers";
import { markers } from "../../state/markers";
import { useSelectedMarker } from "../../state/selectedMarker";
import { useUid } from "../../state/user";

import Marker from "./Marker";

function Markers() {
	const uid = useUid();
	useMarkers();
	const selected = useSelectedMarker();
	const proofer = useIsRole("proofer");
	const scope = Timeline.useScope();

	return markers
		.scope(scope)
		.filter((marker) => !proofer || marker.canView(uid))
		.sort((a, b) => (selected && selected[0] === a.id ? 1 : selected && selected[0] === b.id ? -1 : 0))
		.map((marker) => <Marker key={marker.key} marker={marker} />);
}

export default memo(Markers);
