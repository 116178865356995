import { useCallback, useSyncExternalStore } from "react";

import { markers } from "../state/markers";

export default function useMarkers() {
	const subscribe = useCallback((listener) => markers.subscribe(listener), []);
	const getState = useCallback(() => markers.getAll(), []);

	return useSyncExternalStore(subscribe, getState);
}
