import React, { memo, useCallback, useState } from "react";

import RotateIcon from "astrid-components/lib/components/Assets/Icons/Rotate";
import Button from "astrid-components/lib/components/Inputs/Button";
import Label from "astrid-components/lib/components/Inputs/Label";
import Switch from "astrid-components/lib/components/Inputs/Switch";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Modal from "astrid-components/lib/components/Modules/Modal";
import msToTime from "astrid-helpers/src/msToTime";

import * as master from "../../helpers/master";
import useParts from "../../hooks/useParts";
import { useConnected } from "../../hooks/usePeer";
import useText from "../../hooks/useText";
import { useEdited } from "../../state/edited";
import { useListenLength } from "../../state/listen";
import { setLoadingRender, useLoadingRender } from "../../state/loadingRender";
import { parts } from "../../state/parts";
import { useAdmin } from "../../state/permissions";
import { useProduction } from "../../state/production";
import { setShowRendered, useShowRendered } from "../../state/showRendered";

import RenderModal from "./RenderModal";

function Master() {
	const t = useText();

	useParts();
	const edited = useEdited();
	const length = useListenLength();
	const loading = useLoadingRender();
	const isAdmin = useAdmin();
	const connected = useConnected();
	const production = useProduction();
	const showRendered = useShowRendered();

	const [aborting, setAborting] = useState(false);

	const value = edited;

	const render = useCallback(
		async (push) => {
			setLoadingRender(true);
			Modal.setContent();
			await master.render(value, push);
			setLoadingRender(false);
		},
		[value],
	);

	const onClickRender = useCallback(() => {
		const onConfrim = () => render();

		Modal.setContent(<RenderModal render time={msToTime(value)} onConfirm={onConfrim} />);
	}, [render, value]);

	const onClickSend = useCallback(() => {
		const onConfirm = () => render(true);

		Modal.setContent(<RenderModal time={msToTime(value)} onConfirm={onConfirm} />);
	}, [render, value]);

	const onClickAbort = useCallback(async () => {
		setAborting(true);
		await master.abort();
		setAborting(false);
	}, []);

	const onClickShowRendered = useCallback(() => setShowRendered(!showRendered), [showRendered]);

	const proofedPercentage = production?.getProgressTime("proofed") / production?.getProgressTime("recorded");

	const canRender = production?.reaperTemplates?.master;
	const isProofedAndEdited = proofedPercentage > 0.95 && length === 0;
	const canRenderAndPark = canRender && (isProofedAndEdited || isAdmin);

	return (
		<Label text={t("master", "Master")}>
			<Flex gap={15}>
				{!connected && <Switch value={showRendered} onClick={onClickShowRendered} />}

				{!loading && (!parts.length || parts.completed) ? (
					<>
						<Button transparent size="small" disabled={!canRender} onClick={onClickRender}>
							{t("render", "Render")}
						</Button>

						<Button
							transparent
							size="small"
							color="positive"
							disabled={!canRenderAndPark}
							onClick={onClickSend}
						>
							{t("renderAndPark", "Render & Park")}
						</Button>
					</>
				) : (
					<Flex>
						<RotateIcon size={16} animation="rotate" />{" "}
						<Button transparent size="small" color="negative" disabled={aborting} onClick={onClickAbort}>
							{t("abort", "Abort")}
						</Button>
					</Flex>
				)}
			</Flex>
		</Label>
	);
}

export default memo(Master);
