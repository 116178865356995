import React, { memo } from "react";

import Label from "astrid-components/lib/components/Inputs/Label";
import Select from "astrid-components/lib/components/Inputs/Select";
import { useInputs } from "astrid-helpers/src/mediaDevices";

import useText from "../../hooks/useText";
import { setInput, useInput } from "../../state/input";

function Input() {
	const t = useText();
	const input = useInput();
	const inputs = useInputs();

	return (
		<Label text={t("audioIn", "Audio in")}>
			<Select
				value={input}
				options={inputs}
				placeholder={t("selectAudioIn", "Select audio in")}
				onChange={setInput}
			/>
		</Label>
	);
}

export default memo(Input);
