import React, { memo, useEffect, useState } from "react";

import Speaker from "astrid-components/lib/components/Assets/Icons/Speaker";
import Range from "astrid-components/lib/components/Inputs/Range";
import Dropdown from "astrid-components/lib/components/Modules/Dropdown";

import { sendMessage, useConnected } from "../../hooks/usePeer";
import useText from "../../hooks/useText";
import { startRecorder, stopRecorder } from "../../services/recorder";
import { getActionType, useActionType } from "../../state/action";
import { setMuteStudio, useMuteStudio } from "../../state/muteStudio";

import Button from "./Button";

function onClick() {
	const action = getActionType();
	if (action === "record") return;
	if (action === "start") stopRecorder();
	setMuteStudio((muteStudio) => !muteStudio);
	if (action === "start") startRecorder();
}

function MuteStudio() {
	const t = useText();
	const action = useActionType();
	const connected = useConnected();
	const muteStudio = useMuteStudio();

	const [playback, setPlayback] = useState(1);

	useEffect(() => {
		if (connected) {
			sendMessage("playback", playback);
		}
	}, [connected, playback]);

	return (
		<Dropdown
			trigger={
				<Button
					active={!muteStudio}
					disabled={action === "record"}
					label={!muteStudio ? t("monitorOn", "Monitor on") : t("monitorOff", "Monitor off")}
					onClick={onClick}
				>
					<Speaker />
				</Button>
			}
		>
			<Range step={0.1} min={0} max={2} direction="to top" value={playback} onChange={setPlayback} />
		</Dropdown>
	);
}

export default memo(MuteStudio);
