import React, { memo, useCallback, useMemo, useState } from "react";

import PlusIcon from "astrid-components/lib/components/Assets/Icons/Plus";
import RotateIcon from "astrid-components/lib/components/Assets/Icons/Rotate";
import Pdf from "astrid-components/lib/components/Assets/Pdf";
import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Input from "astrid-components/lib/components/Inputs/Input";
import Select from "astrid-components/lib/components/Inputs/Select";
import Flex from "astrid-components/lib/components/Layout/Flex";
import { languageName, languageOptions } from "astrid-helpers/src/languages";
import useTemp from "astrid-hooks/src/useTemp";

import * as firebase from "../../helpers/firebase";
import useText from "../../hooks/useText";
import useWords from "../../hooks/useWords";
import { useProduction } from "../../state/production";
import { setSelectedWord } from "../../state/word";
import { words } from "../../state/words";

import Word from "./Word";

function WordList() {
	const t = useText();
	const sortedWords = useWords();
	const production = useProduction();
	const selection = Pdf.useSelection();

	const [loading, setLoading] = useState();
	const [language, setLanguage] = useState();
	const [text, setText] = useTemp(selection ? selection.text : "");

	const search = useMemo(() => text?.toLowerCase(), [text]);

	const filtered = useMemo(
		() => (!search ? sortedWords : sortedWords.filter((word) => word.search.includes(search))),
		[search, sortedWords],
	);

	const match = useMemo(() => filtered.find((word) => word.search === search), [filtered, search]);

	const frequency = useMemo(
		() =>
			sortedWords.reduce(
				(map, word) => ({
					...map,
					[word.wordLanguage]: map[word.wordLanguage] ? map[word.wordLanguage] + 1 : 1,
				}),
				{},
			),
		[sortedWords],
	);

	const orderedLanguageOptions = useMemo(
		() =>
			languageOptions
				.map((option) => ({ value: option.value, label: option.text }))
				.sort((a, b) => {
					if (a.value === production.language) return -1;
					if (b.value === production.language) return 1;
					if (a.value === production.languageOriginal) return -1;
					if (b.value === production.languageOriginal) return 1;
					return (frequency[b.value] || 0) - (frequency[a.value] || 0);
				}),
		[frequency, production.language, production.languageOriginal],
	);

	const onClick = useCallback(async () => {
		setLoading(true);
		const word = await words.create(text, language);
		firebase.commit(word);
		setText("");
		setLanguage();
		setLoading(false);
		setSelectedWord(word.id);
	}, [language, setText, text]);

	const onChangeText = useCallback((event) => setText(event.target.value), [setText]);

	return (
		<List>
			<List.Item first>
				<Flex height={45} padding="0 10px">
					<Input
						transparent
						size="small"
						value={text}
						placeholder={t("searchOrAddWord", "Search / add word...")}
						onChange={onChangeText}
					/>
					{text && !match && (
						<Flex marginLeft="auto">
							<Select
								search
								arrow={false}
								value={language}
								options={orderedLanguageOptions}
								placeholder={t("searchLanguage", "Search language")}
								onChange={setLanguage}
							>
								{language ? languageName(language) : t("selectLanguage", "Select language")}
							</Select>
							<div style={{ width: 10 }} />
							{language && (
								<Flex marginLeft="auto">
									<Button
										transparent
										size="small"
										color="primary"
										disabled={loading}
										onClick={onClick}
									>
										{loading ? <RotateIcon size={14} animation="rotate" /> : <PlusIcon size={14} />}
									</Button>
								</Flex>
							)}
						</Flex>
					)}
				</Flex>
			</List.Item>
			{filtered.map((word) => (
				<Word key={word.key} word={word} />
			))}
		</List>
	);
}

export default memo(WordList);
