import { memo, useCallback, useEffect } from "react";
import useNetworkState from "react-use/lib/useNetworkState";

import Phone from "astrid-components/lib/components/Assets/Icons/Phone";
import Button from "astrid-components/lib/components/Inputs/Button";
import Modal from "astrid-components/lib/components/Modules/Modal";
import Text from "astrid-components/lib/components/Text/Text";
import context from "astrid-helpers/src/audioContext";
import useText from "astrid-studio-web/src/hooks/useText";

import usePeer from "../../hooks/usePeer";
import { useSession } from "../../state/session";
import { setShowRendered } from "../../state/showRendered";
import { setStudioConnect, useStudioConnect, useStudioId } from "../../state/studio";
import { studios } from "../../state/studios";

import Session from "./Session";

function Connection() {
	const t = useText();

	const { online } = useNetworkState();

	const session = useSession();
	const studioId = useStudioId();
	const studioConnect = useStudioConnect();

	const studio = studios.useById(studioId);

	const { peer, connect, disconnect, connecting, canConnect, canDisconnect } = usePeer({ studio, online });

	const onClickConnect = useCallback(() => {
		context.resume();
		Modal.setContent();

		setStudioConnect(true);
		setShowRendered(false);

		return connect();
	}, [connect]);

	const onClickDisconnect = useCallback(() => {
		context.resume();
		Modal.setContent();

		setStudioConnect(false);

		return disconnect();
	}, [disconnect]);

	useEffect(() => {
		if (canConnect) {
			setStudioConnect(false);
		}
	}, [canConnect]);

	// Show session logging modal
	useEffect(() => {
		if (session?.data?.finish && !peer) {
			Modal.setContent(<Session onReconnect={canConnect && onClickConnect} />);
		}
	}, [canConnect, onClickConnect, peer, session?.data?.finish]);

	// Show cancelled session modal
	useEffect(() => {
		if (session?.data.status === "cancelled") {
			Modal.setContent(
				<>
					<Text>{t("sessionCancelled", "The session was cancelled.")}</Text>
					<br />
					<Button transparent size="small" onClick={() => Modal.setContent()}>
						{t("close", "Close")}
					</Button>
				</>,
			);
		}
	}, [session, t]);

	// Show done session modal
	useEffect(() => {
		if (session?.data?.status === "done") {
			Modal.setContent(
				<>
					<Text>{t("sessionDone", "The session is done.")}</Text>
					<br />
					<Button transparent size="small" onClick={() => Modal.setContent()}>
						{t("close", "Close")}
					</Button>
				</>,
			);
		}
	}, [session, t]);

	const onClick = studioConnect ? canDisconnect && onClickDisconnect : canConnect && onClickConnect;

	return (
		<Button
			transparent
			disabled={!onClick}
			active={canDisconnect}
			color={canDisconnect ? "negative" : "positive"}
			onClick={onClick || undefined}
		>
			<Phone animation={connecting && "ring"} style={{ transform: canDisconnect && "rotate(135deg)" }} />
		</Button>
	);
}

export default memo(Connection);
