import { useEffect, useRef } from "react";

export default function useAudio({ output, stream, volume = 1, mute = false }) {
	const audio = useRef();

	useEffect(() => {
		if (output && audio.current.setSinkId && output) {
			console.log("Set output audio to", output);
			audio.current.setSinkId(output);
		}
	}, [output]);

	useEffect(() => {
		audio.current.volume = mute ? 0 : volume;
	}, [mute, volume]);

	useEffect(() => {
		if (stream) {
			try {
				audio.current.srcObject = stream;
			} catch (e) {
				audio.current.src = URL.createObjectURL(stream);
			}

			audio.current.play();
		}
	}, [stream]);

	return audio;
}
