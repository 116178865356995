import { useCallback, useSyncExternalStore } from "react";

import { ads } from "../state/ads";

export default function useAds() {
	const subscribe = useCallback((listener) => ads.subscribe(listener), []);
	const getState = useCallback(() => ads.getAll(), []);

	return useSyncExternalStore(subscribe, getState);
}
