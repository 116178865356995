import { useCallback, useSyncExternalStore } from "react";

import { parts } from "../state/parts";

export default function useParts() {
	const subscribe = useCallback((listener) => parts.subscribe(listener), []);
	const getState = useCallback(() => parts.getAll(), []);

	return useSyncExternalStore(subscribe, getState);
}
