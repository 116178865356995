import createStore from "astrid-helpers/src/createStore";
import { getStreamFromInput, setStream } from "astrid-helpers/src/mediaDevices";

export const [getInput, setInput, useInput, onInput] = createStore("default");

async function updateStream() {
	const input = getInput();
	const stream = await getStreamFromInput(input);

	if (stream) {
		setStream(stream);
	}
}

onInput(updateStream);
updateStream();
