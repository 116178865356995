import { useCallback, useSyncExternalStore } from "react";

import { words } from "../state/words";

export default function useWord(id) {
	const subscribe = useCallback((listener) => words.events.on(id, listener), [id]);
	const getState = useCallback(() => words.get(id), [id]);

	return useSyncExternalStore(subscribe, getState);
}
