import createStore from "astrid-helpers/src/createStore";

import { getConnected, useConnected } from "../hooks/usePeer";

import { getMuteStudio, useMuteStudio } from "./muteStudio";

export const [getStudioId, setStudioId, useStudioId, onStudioId] = createStore();
export const [getStudioConnect, setStudioConnect, useStudioConnect, onStudioConnect] = createStore();

export function isStudioActive() {
	const connected = getConnected();
	const muteStudio = getMuteStudio();

	return connected && !muteStudio;
}

export function useStudioActive() {
	const connected = useConnected();
	const muteStudio = useMuteStudio();

	return connected && !muteStudio;
}
