import React, { memo } from "react";

import Download from "astrid-components/lib/components/Assets/Icons/Download";
import FileIcon from "astrid-components/lib/components/Assets/Icons/File";
import Mic from "astrid-components/lib/components/Assets/Icons/Mic";
import SearchIcon from "astrid-components/lib/components/Assets/Icons/Search";
import SettingsIcon from "astrid-components/lib/components/Assets/Icons/Settings";
import WordIcon from "astrid-components/lib/components/Assets/Icons/Word";
import Tabs from "astrid-components/lib/components/Layout/Tabs";

import { useAdmin, useManager, useRecorder } from "../../state/permissions";
import { useProduction } from "../../state/production";
import { useResultsLength } from "../../state/search";
import { setSidebar, useSidebar } from "../../state/sidebar";

import AudioFileList from "../AudioFileList/AudioFileList";
import PartsList from "../PartsList/PartsList";
import Project from "../Project";
import Search from "../Search";
import Settings from "../Settings";
import Studio from "../Studio";
import WordList from "../WordList";

function Sidebar() {
	const index = useSidebar();
	const recorder = useRecorder();
	const admin = useAdmin();
	const manager = useManager();
	const production = useProduction();
	const resultsLength = useResultsLength();

	if (!production) return false;

	const modified = production.modified;

	return (
		<>
			{recorder && <Studio />}

			<Tabs index={index} onChange={setSidebar}>
				<Tabs.Tab Icon={<SearchIcon />} alert={resultsLength}>
					<Search />
				</Tabs.Tab>

				<Tabs.Tab Icon={<WordIcon />}>
					<WordList />
				</Tabs.Tab>

				{(admin || manager) && (
					<Tabs.Tab Icon={<Mic />}>
						<AudioFileList />
					</Tabs.Tab>
				)}

				{(admin || manager) && (
					<Tabs.Tab Icon={<Download />}>
						<PartsList />
					</Tabs.Tab>
				)}

				{recorder && (
					<Tabs.Tab Icon={<FileIcon />} alert={modified}>
						<Project />
					</Tabs.Tab>
				)}

				{recorder && (
					<Tabs.Tab Icon={<SettingsIcon />}>
						<Settings />
					</Tabs.Tab>
				)}
			</Tabs>
		</>
	);
}

export default memo(Sidebar);
