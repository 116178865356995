import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";

import BookOpenIcon from "astrid-components/lib/components/Assets/Icons/BookOpen";
import BookmarkIcon from "astrid-components/lib/components/Assets/Icons/Bookmark";
import MarkerIcon from "astrid-components/lib/components/Assets/Icons/Marker";
import Pdf from "astrid-components/lib/components/Assets/Pdf";
import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Filter from "astrid-components/lib/components/Inputs/Filter";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Text from "astrid-components/lib/components/Text/Text";

import selectMarker from "../../helpers/selectMarker";
import useAds from "../../hooks/useAds";
import useFilteredMarkers from "../../hooks/useFilteredMarkers";
import useFilteredPauses from "../../hooks/useFilteredPauses";
import { useConnected } from "../../hooks/usePeer";
import { useProofer, useRecorder } from "../../state/permissions";
import {
	markerFilterOptions,
	setMarkerFilter,
	setResultsLength,
	setShowMarkers,
	setShowPauses,
	setShowScript,
	useMarkerFilter,
	useShowMarkers,
	useShowPauses,
	useShowScript,
} from "../../state/search";
import { useSelectedMarker } from "../../state/selectedMarker";
import { onSidebar } from "../../state/sidebar";

import Ad from "./Ad";
import Chapter from "./Chapter";
import Form from "./Form";
import Marker from "./Marker";
import Pause from "./Pause";
import Result from "./Result";

function Search() {
	const proofer = useProofer();
	const recorder = useRecorder();
	const connected = useConnected();
	const selectedMarker = useSelectedMarker();

	const search = Pdf.useSearch();
	const results = Pdf.useSearchResults();
	const searchIndex = Pdf.useSearchIndex();

	const showPauses = useShowPauses(true);
	const showScript = useShowScript(true);
	const showMarkers = useShowMarkers(true);
	const markerFilter = useMarkerFilter([]);

	const ads = useAds();
	const pauses = useFilteredPauses(search);
	const markers = useFilteredMarkers(search, markerFilter);

	const ref = useRef();
	const range = useRef({ startIndex: 0, endIndex: 0 });

	const rangeChanged = useCallback((r) => (range.current = r), []);
	const onClickPauses = useCallback(() => setShowPauses((showPauses) => !showPauses), []);
	const onClickScript = useCallback(() => setShowScript((showScript) => !showScript), []);
	const onClickMarkers = useCallback(
		() =>
			setMarkerFilter((markerFilter) =>
				markerFilter.length === markerFilterOptions.length ? [] : markerFilterOptions.map(({ value }) => value),
			),
		[],
	);

	const filter = useMemo(
		() => (
			<List.Item>
				<Flex justifyContent={"left"} height={45} padding="0 15px 0 10px">
					<Flex>
						<Button
							transparent
							size="small"
							active={showPauses}
							color={showPauses ? "primary" : "default"}
							onClick={onClickPauses}
						>
							<BookmarkIcon size={16} />
						</Button>
						<Button
							transparent
							size="small"
							active={showMarkers}
							color={showMarkers ? "primary" : "default"}
							onClick={onClickMarkers}
						>
							<MarkerIcon size={16} />
						</Button>
						{search && (
							<Button
								transparent
								size="small"
								active={showScript}
								color={showScript ? "primary" : "default"}
								onClick={onClickScript}
							>
								<BookOpenIcon size={16} />
							</Button>
						)}
					</Flex>
					{recorder && !search && showMarkers && (
						<Filter options={markerFilterOptions} values={markerFilter} onChange={setMarkerFilter} />
					)}
					<Text color="default" style={{ marginLeft: "auto" }}>
						{markers.length ? "(" + markers.length + ")" : ""}
					</Text>
				</Flex>
			</List.Item>
		),
		[
			markerFilter,
			onClickMarkers,
			onClickPauses,
			onClickScript,
			recorder,
			search,
			showMarkers,
			showPauses,
			showScript,
			markers,
		],
	);

	const [items, staged] = useMemo(() => {
		let items = [];
		let staged = 0;

		if (!search && recorder && ads) {
			items = ads.map((ad) => ({
				order: ad.id === "pre" ? 0 : 9999,
				render: <Ad key={ad.key} ad={ad} />,
			}));
		}

		if (proofer && showPauses) {
			items = items.concat(
				pauses.map((pause) => {
					const Component = pause.type === "chapter" ? Chapter : Pause;
					return {
						order: pause.position / 1000000,
						render: <Component key={pause.position} {...pause} />,
					};
				}),
			);
		}

		if (showMarkers) {
			items = items.concat(
				markers.map((marker) => {
					if (marker.staged) staged += 1;

					return {
						id: marker.id,
						order: marker.time / 1000,
						render: <Marker key={marker.key} marker={marker} />,
					};
				}),
			);
		}

		if (showScript) {
			items = items.concat(
				results.map((result, index) => ({
					order: result.page,
					render: (
						<Result key={index} search={search} index={index} active={searchIndex === index} {...result} />
					),
				})),
			);
		}

		return [items.sort((a, b) => a.order - b.order), staged];
	}, [ads, markers, pauses, proofer, recorder, results, search, searchIndex, showMarkers, showPauses, showScript]);

	const item = (index) => {
		if (index === 0) {
			return <Form />;
		} else if (index === 1) {
			return proofer ? filter : false;
		} else if (items[index - 2]) {
			return items[index - 2].render;
		}

		return false;
	};

	useEffect(() => {
		if (!search) {
			setShowScript(true);
			setShowMarkers(true);
		}
	}, [search]);

	useEffect(() => {
		setResultsLength((search && items.length) || staged);
	}, [items.length, search, staged]);

	useEffect(() => {
		if (selectedMarker && selectedMarker[0] && selectedMarker[1]) {
			const item = items.find((item) => item.id === selectedMarker[0]);

			if (item) {
				selectMarker(selectedMarker[0], false);

				// const { startIndex, endIndex } = range.current;
				// const index = items.indexOf(item);

				// if (index < startIndex || index > endIndex) {
				ref.current.scrollToIndex(items.indexOf(item));
				// }
			}
		}
	}, [selectedMarker, items]);

	useEffect(
		() =>
			onSidebar((sidebar) => {
				if (sidebar === 0) {
					ref.current.scrollToIndex(0);
				}
			}),
		[],
	);

	return (
		<List.Variable
			ref={ref}
			itemContent={item}
			// topItems={2}
			rangeChanged={rangeChanged}
			totalCount={items.length + 2}
		/>
	);
}

export default memo(Search);
