import context from "./audioContext";
import { getStream } from "./mediaDevices";

function floatTo16BitPCM(input, output) {
	for (let i = 0; i < input.length; i++) {
		const s = Math.max(-1, Math.min(1, input[i]));
		output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
	}
}

function convertBuffer(arrayBuffer) {
	const data = new Float32Array(arrayBuffer);
	const out = new Int16Array(arrayBuffer.length);

	floatTo16BitPCM(data, out);

	return out;
}

export function start() {
	context.resume();

	const data = [];

	const encoder = new window.lamejs.Mp3Encoder(1, context.sampleRate || 48000, 192);
	const source = context.createMediaStreamSource(getStream());
	const processor = context.createScriptProcessor(0, 1, 1);

	source.connect(processor);
	processor.connect(context.destination);

	processor.onaudioprocess = (event) => {
		const maxSamples = 1152;
		const arrayBuffer = event.inputBuffer.getChannelData(0);
		const samplesMono = convertBuffer(arrayBuffer);

		let remaining = samplesMono.length;

		for (let i = 0; remaining >= 0; i += maxSamples) {
			data.push(encoder.encodeBuffer(samplesMono.subarray(i, i + maxSamples)));

			remaining -= maxSamples;
		}
	};

	return {
		stop() {
			processor.onaudioprocess = null;

			source.disconnect(processor);
			processor.disconnect(context.destination);

			const buffer = encoder.flush();

			if (buffer.length > 0) {
				data.push(buffer);
			}

			return new Blob(data, { type: "audio/mp3" });
		},
	};
}
