import { useCallback, useSyncExternalStore } from "react";

import { recordings } from "../state/recordings";

export default function useRecordings() {
	const subscribe = useCallback((listener) => recordings.subscribe(listener), []);
	const getState = useCallback(() => recordings.getAll(), []);

	return useSyncExternalStore(subscribe, getState);
}
