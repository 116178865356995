import semver from "semver";

import createCollection from "../helpers/createCollection";
import * as firebase from "../helpers/firebase";

import { onProductionChanged } from "./production";

export const studios = createCollection();

const min = "1.15.1";

firebase.events.on("studios", (snapshot) => {
	studios.setSnapshot(
		snapshot
			.filter(
				(studio) =>
					studio.status !== "offline" &&
					(studio.data.version === "dev" ||
						(semver.valid(studio.data.version) && semver.gte(studio.data.version, min))),
			)
			.sort((a, b) => a.data.name.localeCompare(b.data.name)),
	);
});

onProductionChanged((production) => {
	if (production.producer) {
		firebase.worker.studios(production.producer);

		return () => studios.resetState();
	}
});
