import { useDebouncedCallback } from "use-debounce";

import React, { memo, useCallback, useState } from "react";

import PlusIcon from "astrid-components/lib/components/Assets/Icons/Plus";
import Button from "astrid-components/lib/components/Inputs/Button";
import Input from "astrid-components/lib/components/Inputs/Input";
import Label from "astrid-components/lib/components/Inputs/Label";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Modal from "astrid-components/lib/components/Modules/Modal";
import Header from "astrid-components/lib/components/Text/Header";
import Text from "astrid-components/lib/components/Text/Text";
import msToTime from "astrid-helpers/src/msToTime";
import timeToMs from "astrid-helpers/src/timeToMs";

import * as firebase from "../../helpers/firebase";
import useText from "../../hooks/useText";
import { setSession, useSession } from "../../state/session";

function onClickClose() {
	Modal.setContent();
}

function Session({ onReconnect }) {
	const t = useText();
	const session = useSession();

	const [pageInterval, setPageInterval] = useState();

	const onChange = useDebouncedCallback(
		useCallback(
			(key, value) => {
				firebase.write([["update", session.path, { [key]: value }]]);
			},
			[session?.path],
		),
		500,
	);

	const onClickSetPageInterval = useCallback(() => {
		setPageInterval({ in: 0, out: 0 });
	}, []);

	const onClickAddPageInterval = useCallback(() => {
		onChange("pageInterval", [...(session?.data.pageInterval || []), pageInterval]);
		setPageInterval();
	}, [onChange, pageInterval, session?.data.pageInterval]);

	const onClickDeletePageInterval = useCallback(
		(index) => onChange("pageInterval", session?.data.pageInterval?.filter((v, i) => i !== index) || []),
		[onChange, session?.data.pageInterval],
	);

	const onClickDone = useCallback(() => {
		if (window.confirm(t("saveAndSubmitSession", "Do you want so save and sumbit the session?"))) {
			onClickClose();
			setSession(null);
			window.history.pushState(null, null, "/");
			firebase.worker.session();
			firebase.write([["update", session?.path, { status: "done" }]]);
		}
	}, [session, t]);

	if (!session) return false;

	const disabled = session.data.status === "done";

	return (
		<div style={{ textAlign: "center" }}>
			<Header textSize="large">{t("sessionFinishedHeader", "Connection lost - is session finnished?")}</Header>

			<div style={{ height: 30 }} />

			<div>
				{!session.data.arrival?.seconds && (
					<Label text={t("session", "Session")}>
						<Input.Time
							size="small"
							disabled={disabled}
							showSeconds={false}
							value={timeToMs(session.data.arrival || "00:00")}
							style={{ textAlign: "center", width: 100 }}
							onChange={(change) => onChange("arrival", msToTime(change, false, false))}
						/>

						{!session.data.finish?.seconds && (
							<Input.Time
								size="small"
								disabled={disabled}
								showSeconds={false}
								value={timeToMs(session.data.finish || "00:00")}
								style={{ textAlign: "center", width: 100 }}
								onChange={(change) => onChange("finish", msToTime(change, false, false))}
							/>
						)}
					</Label>
				)}

				<Label text={t("timeline", "Timeline")}>
					<Input.Time
						size="small"
						disabled={disabled}
						value={timeToMs(session.data.cubaseIn || "00:00:00")}
						style={{ textAlign: "center", width: 100 }}
						onChange={(change) => onChange("cubaseIn", msToTime(change, false))}
					/>

					<Input.Time
						size="small"
						disabled={disabled}
						value={timeToMs(session.data.cubaseOut || "00:00:00")}
						style={{ textAlign: "center", width: 100 }}
						onChange={(change) => onChange("cubaseOut", msToTime(change, false))}
					/>
				</Label>

				<Label text={t("pageInterval", "Pages")}>
					<Flex gap={10}>
						{session.data.pageInterval.map((interval, index) => (
							<Button
								key={index}
								size="small"
								color="negative"
								disabled={disabled}
								onClick={() => onClickDeletePageInterval(index)}
							>
								<span>
									{interval.in} - {interval.out}
								</span>

								<PlusIcon size={11} style={{ marginLeft: 5 }} transform="rotate(45)" />
							</Button>
						))}

						{!pageInterval ? (
							<Button
								transparent
								size="small"
								color="positive"
								disabled={disabled}
								onClick={onClickSetPageInterval}
							>
								{t("add", "Add")}
							</Button>
						) : (
							<>
								<Input
									size="small"
									disabled={disabled}
									value={pageInterval.in}
									placeholder={t("start", "Start")}
									style={{ textAlign: "center", width: 50 }}
									onChange={({ target }) =>
										setPageInterval((pageInterval) => ({
											...pageInterval,
											in: parseInt(target.value) || 0,
										}))
									}
								/>

								<Input
									size="small"
									disabled={disabled}
									value={pageInterval.out}
									placeholder={t("stop", "Stop")}
									style={{ textAlign: "center", width: 50 }}
									onChange={({ target }) =>
										setPageInterval((pageInterval) => ({
											...pageInterval,
											out: parseInt(target.value) || 0,
										}))
									}
								/>

								<Button
									transparent
									size="small"
									color="positive"
									disabled={
										!pageInterval.in || !pageInterval.out || pageInterval.in > pageInterval.out
									}
									onClick={onClickAddPageInterval}
								>
									<PlusIcon size={14} />
								</Button>
							</>
						)}
					</Flex>
				</Label>
			</div>

			<div style={{ height: 30 }} />

			<Flex flex={1} justifyContent="center">
				<Button color="positive" onClick={onClickDone}>
					{t("yesLogSession", "Yes - Log session")}
				</Button>

				{onReconnect && (
					<>
						<Text style={{ margin: 20 }}>{t("otherwise", "or")}</Text>
						<Button color="primary" onClick={onReconnect}>
							{t("noReconnect", "No - Reconnect")}
						</Button>
					</>
				)}
			</Flex>

			<div style={{ height: 10 }} />

			<Button transparent size="small" onClick={onClickClose}>
				{t("close", "Close")}
			</Button>
		</div>
	);
}

export default memo(Session);
