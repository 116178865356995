import { useCallback, useSyncExternalStore } from "react";

import { words } from "../state/words";

export default function useWords() {
	const subscribe = useCallback((listener) => words.subscribe(listener), []);
	const getState = useCallback(() => words.getAll(), []);

	return useSyncExternalStore(subscribe, getState);
}
