import { useCallback, useSyncExternalStore } from "react";

import { clips } from "../state/clips";

export default function useClip(id) {
	const subscribe = useCallback((listener) => clips.events.on(id, listener), [id]);
	const getState = useCallback(() => clips.get(id), [id]);

	return useSyncExternalStore(subscribe, getState);
}
