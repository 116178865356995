import { memo } from "react";

import GlobalStyle from "astrid-components/lib/components/GlobalStyle";
import Divider from "astrid-components/lib/components/Layout/Divider";
import Grid from "astrid-components/lib/components/Layout/Grid";
import Panel from "astrid-components/lib/components/Layout/Panel";
import Modal from "astrid-components/lib/components/Modules/Modal";
import MouseLabel from "astrid-components/lib/components/Modules/MouseLabel";
import Popup from "astrid-components/lib/components/Modules/Popup";
import Theme from "astrid-components/lib/components/Theme";

import useSentry from "../hooks/useSentry";
import { useProofer, useReader, useRecorder } from "../state/permissions";
import { useTheme } from "../state/theme";

import Audio from "./Audio";
import Navigation from "./Navigation";
import OfflinePopup from "./OfflinePopup";
import Script from "./Script";
import Sidebar from "./Sidebar";
import Timeline from "./Timeline";
import Toolbar from "./Toolbar";

function App() {
	useSentry();

	const theme = useTheme();
	const loaded = useReader();
	const proofer = useProofer();
	const recorder = useRecorder();

	return (
		<Theme mode={theme}>
			<GlobalStyle />

			<Audio />

			<Grid three={loaded && proofer} height={recorder ? 40 : 26}>
				{loaded && proofer && (
					<Grid.AreaOne>
						<Sidebar />
					</Grid.AreaOne>
				)}

				<Grid.AreaTwo>
					<Script />
				</Grid.AreaTwo>

				{loaded && proofer && (
					<Grid.AreaThree>
						<Panel style={{ height: "100%" }} overflow="hidden" background="secondary">
							<Toolbar />
							<Divider />
							<Timeline />
							<Divider />
							<Navigation />
						</Panel>
					</Grid.AreaThree>
				)}
			</Grid>

			<Modal />

			<Popup />

			<MouseLabel />

			<OfflinePopup />
		</Theme>
	);
}

export default memo(App);
