import { sendMessage } from "../hooks/usePeer";
import { clips } from "../state/clips";
import { isRole } from "../state/permissions";
import { isStudioActive } from "../state/studio";

let loadedId;

export default function loadClipAtPosition(position) {
	const clip = clips.atPosition(position);

	if (clip) {
		if (isStudioActive()) {
			if (clip.id !== loadedId) {
				loadedId = clip.id;

				sendMessage("load", clip.recording.url);
			}
		} else if (!isRole("proofer")) {
			clip.recording.load();
		}
	}
}
