import eventTarget from "astrid-helpers/src/eventTarget";

export default class Collection {
	constructor() {
		this.count = 0;
		this.sorted = [];
		this.loaded = false;
		this.documents = new Map();
		this.events = eventTarget();
	}

	clear() {
		this.sorted = [];
		this.loaded = false;
		this.documents.clear();

		this.update();
	}

	get length() {
		return this.sorted.length;
	}

	get first() {
		return this.sorted[0];
	}

	get last() {
		return this.sorted[this.length - 1];
	}

	set snapshot(snapshot) {
		snapshot.forEach(({ id, data, type }) => {
			if (type === "removed") {
				this.documents.delete(id);
			} else {
				this.set(id, data);
			}
		});

		this.loaded = true;
		this.update();
	}

	set(id, data) {
		if (!data.deleted) {
			this.documents.set(id, data);
		} else if (this.documents.has(id)) {
			this.documents.delete(id);
		}

		this.events.emit(id, data);
	}

	get(id) {
		return this.documents.get(id);
	}

	getAll() {
		return this.sorted;
	}

	create(data) {
		return data;
	}

	update(trickle = true) {
		this.sorted = [...this.documents.values()].filter(this.filterer).sort(this.sorter);
		this.events.emit("update", this.count++, trickle);
	}

	subscribe(listener) {
		return this.events.on("update", listener);
	}

	filterer(a) {
		return a;
	}

	sorter() {
		return 0;
	}

	slice() {
		return this.sorted.slice();
	}

	sort(fn) {
		return this.slice().sort(fn);
	}

	map(fn) {
		return this.sorted.map(fn);
	}

	find(fn) {
		return this.sorted.find(fn);
	}

	filter(fn) {
		return this.sorted.filter(fn);
	}

	forEach(fn) {
		return this.sorted.forEach(fn);
	}

	indexOf(fn) {
		return this.sorted.indexOf(fn);
	}

	reduce(...args) {
		return this.sorted.reduce(...args);
	}
}
