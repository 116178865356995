import { useCallback, useSyncExternalStore } from "react";

import eventTarget from "astrid-helpers/src/eventTarget";

import Precording from "../classes/precording";

import { recordings } from "./recordings";

export const events = eventTarget();

export const store = {
	count: 0,
	precordings: {},
};

export function clearPrecordings() {
	store.count = 0;
	store.precordings = {};
}

export function getPrecording(number) {
	return store.precordings[number];
}

export function getLastPrecordingNumber() {
	return store.number;
}

export function getLastPrecording() {
	return store.precordings[store.number];
}

export function getPrecordingValue(number, key) {
	return store.precordings[number] && store.precordings[number][key];
}

export function publishPrecording(number) {
	store.count += 1;
	events.emit(number, store.count);
}

export function createPrecording(action) {
	store.number = recordings.nextNumber;

	return (store.precordings[store.number] = new Precording(store.number, action));
}

export function usePrecording(number) {
	const subscribe = useCallback((listener) => events.on(number, listener), [number]);
	const getState = useCallback(() => store.precordings[number]?.length, [number]);

	useSyncExternalStore(subscribe, getState);

	return store.precordings[number];
}
