import styled from "styled-components";

import React, { useEffect } from "react";
import { browserName } from "react-device-detect";
import useNetworkState from "react-use/lib/useNetworkState";

import Modal from "astrid-components/lib/components/Modules/Modal";

import useText from "../../hooks/useText";

import BrowserModal from "../BrowserModal/BrowserModal";

const Container = styled.div`
	width: 400px;
	position: absolute;
	right: 20px;
	bottom: 20px;
	background-color: red;
	border-radius: 5px;
	padding: 20px;
	text-align: center;
	color: white;
`;

const Text = styled.p`
	font-size: 15px;
	margin: 0;
`;

export default function OfflinePopup() {
	const t = useText();

	const { online } = useNetworkState();

	useEffect(() => {
		if (browserName !== "Chrome") {
			Modal.setContent(<BrowserModal />);
		}
	}, []);

	if (online) {
		return null;
	}

	return (
		<Container>
			<Text>{t("connectionLost", "Internet connection was lost!")}</Text>
		</Container>
	);
}
