import React, { memo } from "react";

import Pdf from "astrid-components/lib/components/Assets/Pdf";
import Divider from "astrid-components/lib/components/Layout/Divider";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Panel from "astrid-components/lib/components/Layout/Panel";

import Production from "../Production";

import ActiveUsers from "./ActiveUsers";

function Script() {
	const document = Pdf.useDocument();

	return (
		<Panel background="secondary" style={{ height: "100%" }}>
			<Flex alignItems="stretch">
				<Flex alignItems="stretch" width="100%" maxWidth={330}>
					<Flex flex={1} padding="8px 20px">
						<Production />
					</Flex>

					<Divider vertical />

					<Flex padding="8px">
						<ActiveUsers />
					</Flex>
				</Flex>

				<div style={{ width: 8 }}></div>

				<Pdf.Toolbar />
			</Flex>

			<Divider />

			{document && <Pdf />}
		</Panel>
	);
}

export default memo(Script);
