import { useCallback, useSyncExternalStore } from "react";

import { recordings } from "../state/recordings";

export default function useRecording(id) {
	const subscribe = useCallback((listener) => recordings.events.on(id, listener), [id]);
	const getState = useCallback(() => recordings.get(id), [id]);

	return useSyncExternalStore(subscribe, getState);
}
